/**

	@Projekt: 	
	@Autor:   	LEMONIT
	@Zakodował: MK
	@Data:      09/2018
	-------------------
	SKRYPTY.JS
	-------------------

**/

var LEMON = {

    buttonSteps:function(){
        $('.step-down').click(function(){
            var $parent = $(this).parent('div'), $input = $parent.find('input'), $value = $input.val(), $min = $input.attr('min');
            if($value != $min){
                $input.val(--$value);
            }
        })

        $('.step-up').click(function(){
            var $parent = $(this).parent('div'), $input = $parent.find('input'), $value = $input.val(), $max = $input.attr('max');
            if($value != $max){
                $input.val(++$value);
            }
        })
    },
    galleryItem:function() {
        $('.accessories-thumbnails-image').click(function(e){
            e.preventDefault();
            var $this = $(this);
            $('.accessories-thumbnails-item').removeClass('active');
            $this.parent('.accessories-thumbnails-item').addClass('active');
            $('.accessories-images-figure').attr('src', $(this).attr('src'));
        });

        $('.open-gallery-link').click(function(e) {
            e.preventDefault();

            var items = [], index;
            $('.accessories-thumbnails-image').each(function() {
                items.push({
                    src: $(this).attr('data-url')
                });
            });
            index = $('.accessories-thumbnails-item.active').index()

            $.magnificPopup.open({
                items: items,
                type: 'image',
                removalDelay: 300,
                mainClass: 'mfp-fade',
                tClose: 'Zamknij (Esc)',
                tLoading: 'Wczytywanie...',
                gallery: {
                    tPrev: 'Poprzedni (Lewy klawisz)',
                    tNext: 'Następny (Prawy klawisz)',
                    tCounter: '%curr% z %total%',
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0,1] // Will preload 0 - before current, and 1 after the current image
                },
                image: {
                    tError: '<a href="%url%">Obrazek</a> nie mogł zostać załadowany.',
                    verticalFit: true
                },
                ajax: {
                    tError: '<a href="%url%">Połączenie</a> odrzucono.'
                }
            }, index);
        });
    },
	homepageCarousel:function(){
        var $autoplayTime = 10000;

		$('.homepage-slider-carousel').owlCarousel({
            autoplay: true,
            autoplayHoverPause: false,
            autoplayTimeout: $autoplayTime,
            dots: false,
            dotsContainer: '.homepage-slider-dots',
            items: 1,
            loop: true,
            nav: true,
            navContainer: '.homepage-slider-controls',
            navText: ['<i class="icon-lewo"></i>','<i class="icon-prawo"></i>'],
            mouseDrag: true,
            smartSpeed: 500,
			onTranslate: function(event){
                $('.homepage-slider-carousel').trigger('stop.owl.autoplay');
            },
            onTranslated: function(event){
                $('.homepage-slider-carousel').trigger('play.owl.autoplay',[$autoplayTime]);
            }
		});
	},
    inputNumber:function(){
        $("input[type='number']").on("input", function() {
            var nonNumReg = /[^0-9]/g
            $(this).val($(this).val().replace(nonNumReg, ''));
        });
    },
    mobileMenu:function(){
        var $hamburger = $('.rwd-hamburger'),
            $aside = $('.rwd');

        $hamburger.click(function(){
            $hamburger.toggleClass('is-active');
            $aside.toggleClass('is-active');
        });
    },
    productAbonament:function(){
        function countAbonament() {
            var $amountProduct = isNaN(parseInt($('.subscription-amount').val())) ? 1 : parseInt($('.subscription-amount').val()),
                $singlePrice = 0,
                $amountUnits = 0,
                $deliverCounter = 4,
                $attrValue = $('select.subscription-variant').data('attr'),
                $selectedVariant = parseInt($('select.subscription-variant').val()),
                $selectedVariantProductId = 0,
                $allVariantsData = Object.values($('.subscription-form').data('variants')),
                $allVariantsProductId = Object.keys($('.subscription-form').data('variants'));

            if($amountProduct < 2) {
                $amountProduct = 1;
                $('.subscription-amount').val(1);
            }
            if($amountProduct > 100) {
                $amountProduct = 100;
                $('.subscription-amount').val(100);
            }

            if($allVariantsData.length > 0){
                for($i = 0; $i < $allVariantsData.length; $i++){
                    if($allVariantsData[$i].attr[$attrValue] == $selectedVariant){
                        $selectedVariantProductId = $allVariantsProductId[$i];
                        $singlePrice = parseFloat($allVariantsData[$i].price);
                        $amountUnits = parseInt($allVariantsData[$i].ilosc_jednostek) == 0 ? 1 : parseInt($allVariantsData[$i].ilosc_jednostek);
                    }
                }

                var $amountOneDeliverProducts = $amountProduct * $amountUnits,
                    $amountAllDeliversProducts = $amountProduct * $amountUnits * $deliverCounter,
                    $lastDigitFirstWord = ($amountProduct * $amountUnits) % 10,
                    $lastDigitSecondWord = ($amountProduct * $amountUnits * $deliverCounter) % 10,
                    $firstWord, $secondWord;

                if($amountOneDeliverProducts <= 1){
                    $firstWord = "szczoteczkę";
                } else if($amountOneDeliverProducts <= 4){
                    $firstWord = "szczoteczki";
                } else if($amountOneDeliverProducts <= 21){
                    $firstWord = "szczoteczek";
                } else if($lastDigitFirstWord == 2 || $lastDigitFirstWord == 3 || $lastDigitFirstWord == 4){
                    $firstWord = "szczoteczki";
                } else {
                    $firstWord = "szczoteczek";
                }

                if($amountAllDeliversProducts <= 1){
                    $secondWord = "szczoteczkę";
                } else if($amountAllDeliversProducts <= 4){
                    $secondWord = "szczoteczki";
                } else if($amountAllDeliversProducts <= 21){
                    $secondWord = "szczoteczek";
                } else if($lastDigitSecondWord == 2 || $lastDigitSecondWord == 3 || $lastDigitSecondWord == 4){
                    $secondWord = "szczoteczki";
                } else {
                    $secondWord = "szczoteczek";
                }

                if($amountOneDeliverProducts <= 1){
                    $('.subscription-description').html('Zamawiasz 4 dostawy, a w każdej 1 szczoteczkę. <strong>W sumie otrzymasz <span class="product-sum">'+$amountProduct * $amountUnits * $deliverCounter+'</span> '+$secondWord+'.</strong><br/>Pierwsza dostawa powinna dotrzeć w ciągu 7 dni, a kolejne co 3 miesiące.</p>');
                } else {
                    $('.subscription-description').html('Zamawiasz 4 dostawy, a w każdej '+$amountProduct * $amountUnits+' '+$firstWord+'. <strong>W sumie otrzymasz <span class="product-sum">'+$amountProduct * $amountUnits * $deliverCounter+'</span> '+$secondWord+'.</strong><br/>Pierwsza dostawa powinna dotrzeć w ciągu 7 dni, a kolejne co 3 miesiące.</p>');

                }

                //$('.product-counter').text($amountProduct * $amountUnits);
                $('.product-sum').text($amountProduct * $amountUnits * $deliverCounter);
                $('.subscription-sum').text(($amountProduct * $singlePrice * $deliverCounter).toFixed(2));
                $('.subscription-id').val($selectedVariantProductId);
            }
        }

        $('.subscription-variant').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            countAbonament();
        });

        $('.subscription-button').click(function(){
            countAbonament();
        });

        $('.subscription-amount').focusout(function(){
            countAbonament();
        });
    },
    productAbonamentSingle:function(){
        function countAbonamentSingle() {
            var $amountProduct = isNaN(parseInt($('.once-amount').val())) ? 1 : parseInt($('.once-amount').val()),
                $singlePrice = 0,
                $attrValue = $('select.once-variant').data('attr'),
                $selectedVariant = parseInt($('select.once-variant').val()),
                $selectedVariantProductId = 0,
                $allVariantsData = Object.values($('.once-form').data('variants')),
                $allVariantsProductId = Object.keys($('.once-form').data('variants'));

            if($amountProduct < 2) {
                $amountProduct = 1;
                $('.once-amount').val(1);
            }
            if($amountProduct > 100) {
                $amountProduct = 100;
                $('.once-amount').val(100);
            }

            for($i = 0; $i < $allVariantsData.length; $i++){
                if($allVariantsData[$i].attr[$attrValue] == $selectedVariant){
                    $selectedVariantProductId = $allVariantsProductId[$i];
                    $singlePrice = parseFloat($allVariantsData[$i].price);
                }
            }

            $('.once-price').text(($amountProduct * $singlePrice).toFixed(2));
            $('.once-id').val($selectedVariantProductId);
        }

        $('.once-variant').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            countAbonamentSingle();
        });

        $('.once-button').click(function(){
            countAbonamentSingle();
        });

        $('.once-amount').focusout(function(){
            countAbonamentSingle();
        });
    },
    productAccessories:function(){
        function countAccessories() {
            if($('.accessories-variant').length == 0) return false;
            var $amountProduct = parseInt($('.accessories-amount').val()),
                $singlePrice = 0,
                $attrValue = $('select.accessories-variant').data('attr'),
                $selectedVariant = parseInt($('select.accessories-variant').val()),
                $selectedVariantProductId = 0,
                $allVariantsData = Object.values($('.accessories-form').data('variants')),
                $allVariantsProductId = Object.keys($('.accessories-form').data('variants'));

            for($i = 0; $i < $allVariantsData.length; $i++){
                if($allVariantsData[$i].attr[$attrValue] == $selectedVariant){
                    $selectedVariantProductId = $allVariantsProductId[$i];
                    $singlePrice = parseFloat($allVariantsData[$i].price);
                }
            }

            //$('.accessories-variant-price').text(($amountProduct * $singlePrice).toFixed(2));
            $('.accessories-id').val($selectedVariantProductId);
        }

        $('.accessories-variant').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            countAccessories();
        });

        $('.accessories-button').click(function(){
            countAccessories();
        });

        $('.accessories-amount').focusout(function(){
            countAccessories();
        });
    },
    rangeOrder:function(){
	    function countB2B(){
	        var $sumProduct = 0, $sumPrice = 0, $deliverCounter = 0;
            $('.range-item').each(function(){
                var $productCounter = parseInt($(this).find('.range-amount-item').val()) == '' ? 0 : parseInt($(this).find('.range-amount-item').val()),
                    $amountUnits = parseInt($(this).data('units')) == 0 ? 1 : parseInt($(this).data('units')),
                    $priceVariant;
                switch ($productCounter) {
                    case 0:
                        $priceVariant = 0;
                        $productCounter = 0;
                        break;
                    case 10:
                        $priceVariant = parseFloat($(this).data('price_10'));
                        $productCounter = 10;
                        break;
                    case 25:
                        $priceVariant = parseFloat($(this).data('price_25'));
                        $productCounter = 25;
                        break;
                    case 50:
                        $priceVariant = parseFloat($(this).data('price_50'));
                        $productCounter = 50;
                        break;
                    case 100:
                        $priceVariant = parseFloat($(this).data('price_100'));
                        $productCounter = 100;
                        break;
                }
                $sumProduct += $productCounter * $amountUnits;
                $sumPrice += $priceVariant;
            });
	        switch ($('select.range-select').val()){
                case '1m':
                    $('.deliver-counter').text('12');
                    $deliverCounter = 12;
                    $('.range-description').html('Zamawiasz 12 dostaw, a w każdej produktów: '+$sumProduct+'. <strong>W sumie otrzymasz produktów: '+$sumProduct * $deliverCounter+'</strong>.');
                    $('.range-frequency').html('Pierwsza dostawa dotrze w ciągu 7 dni, a kolejne <strong>co 1 miesiąc</strong>.');
                    break;
                case '3m':
                    $('.deliver-counter').text('4');
                    $deliverCounter = 4;
                    $('.range-description').html('Zamawiasz 4 dostawy, a w każdej produktów: '+$sumProduct+'. <strong>W sumie otrzymasz produktów: '+$sumProduct * $deliverCounter+'</strong>.');
                    $('.range-frequency').html('Pierwsza dostawa dotrze w ciągu 7 dni, a kolejne <strong>co 3 miesiące</strong>.');
                    break;
                case '6m':
                    $('.deliver-counter').text('2');
                    $deliverCounter = 2;
                    $('.range-description').html('Zamawiasz 2 dostawy, a w każdej produktów: '+$sumProduct+'. <strong>W sumie otrzymasz produktów: '+$sumProduct * $deliverCounter+'</strong>.');
                    $('.range-frequency').html('Pierwsza dostawa dotrze w ciągu 7 dni, a druga <strong>za 6 miesiący</strong>.');
                    break;
                case '1y':
                    $deliverCounter = 1;
                    $('.range-description').html('Zamawiasz jedną dostawę szczoteczek. <strong>W sumie otrzymasz produktów: '+$sumProduct * $deliverCounter+'</strong>.');
                    $('.range-frequency').html('Dostawa dotrze w ciągu 7 dni.');
                    break;
            }
	        $('.sum-price').text(($sumPrice * $deliverCounter).toFixed(2));

	        if($sumProduct > 0){
	            $('.range-ifnull').removeClass('hidden');
            } else {
                $('.range-ifnull').addClass('hidden');
            }
        }

        var $labels = ['0', '10', '25', '50', '100'];

        $('.range-slider').slider({
            value: 0,
            min: 0,
            max: $labels.length - 1,
            change: function( event, ui ) {
                var $item = $(this).closest('.range-item');
                switch (ui.value) {
                    case 0:
                        $item.find('.range-amount-item').val(0);
                        $item.find('.range-amount-item').data('slider-value', 0);
                        break;
                    case 1:
                        $item.find('.range-amount-item').val(10);
                        $item.find('.range-amount-item').data('slider-value', 10);
                        break;
                    case 2:
                        $item.find('.range-amount-item').val(25);
                        $item.find('.range-amount-item').data('slider-value', 25);
                        break;
                    case 3:
                        $item.find('.range-amount-item').val(50);
                        $item.find('.range-amount-item').data('slider-value', 50);
                        break;
                    case 4:
                        $item.find('.range-amount-item').val(100);
                        $item.find('.range-amount-item').data('slider-value', 100);
                        break;
                }
                countB2B();
            }
        }).slider('pips',{
            rest: "label",
            suffix: " szt.",
            labels: $labels
        });

        $('.ui-slider').promise().done(function() {
            $('.range-item').each(function(){
                var $price10 = $(this).data('price_10'),
                    $price25 = $(this).data('price_25'),
                    $price50 = $(this).data('price_50'),
                    $price100 = $(this).data('price_100');

                $(this).find('.ui-slider-pip-1').append('<span class="ui-slider-label range-label" data-value="1">' + $price10 + ' zł</span><span class="ui-slider-label range-label-lower" data-value="1">(' + ($price10 / 10).toFixed(2) + 'zł / szt)</span>')
                $(this).find('.ui-slider-pip-2').append('<span class="ui-slider-label range-label" data-value="2">' + $price25 + ' zł</span><span class="ui-slider-label range-label-lower" data-value="2">(' + ($price25 / 25).toFixed(2) + 'zł / szt)</span>')
                $(this).find('.ui-slider-pip-3').append('<span class="ui-slider-label range-label" data-value="3">' + $price50 + ' zł</span><span class="ui-slider-label range-label-lower" data-value="3">(' + ($price50 / 50).toFixed(2) + 'zł / szt)</span>')
                $(this).find('.ui-slider-pip-4').append('<span class="ui-slider-label range-label" data-value="4">' + $price100 + ' zł</span><span class="ui-slider-label range-label-lower" data-value="4">(' + ($price100 / 100).toFixed(2) + 'zł / szt)</span>')
            });
        });

        $('.range-select').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            countB2B();
        });

        $('.range-custom-amount').keyup(function(){
            var $item = $(this).closest('.range-item'),
                $hiddenInput = $item.find('.range-amount-item'),
                $value = $(this).val() == '' ? 0 : $(this).val();

            if($value >= 0) {
                $hiddenInput.val($value);
                countB2B();
            }
        });

        $('.range-custom-amount').focusout(function(){
            var $item = $(this).closest('.range-item'),
                $hiddenInput = $item.find('.range-amount-item'),
                $value = $(this).val() == '' ? 0 : $(this).val();

            if($value < 0) {
                $value = 0;
                $(this).val(0);
            }
            $hiddenInput.val($value);
            countB2B();
        });

        $('.range-custom-checkbox').click(function(){
            var $item = $(this).closest('.range-item'),
                $customInput = $item.find('.range-custom-amount'),
                $hiddenInput = $item.find('.range-amount-item');

            $item.toggleClass('custom-selected');
            if($item.hasClass('custom-selected')){
                $customInput.prop('disabled', false);
                $customInput.val(110);
                $hiddenInput.val($customInput.val());
                $('.range-defined').addClass('hidden');
                $('.range-other').removeClass('hidden');
                countB2B();
            } else {
                $customInput.prop('disabled', true);
                $customInput.val('');
                $hiddenInput.val($hiddenInput.data('slider-value'));
                if($('.range-custom-amount').filter(':not(:disabled)').length == 0){
                    $('.range-other').addClass('hidden');
                    $('.range-defined').removeClass('hidden');
                }
                countB2B();
            }
        });
    },
    responsiveTable:function() {
        $('table').each(function() {
            var $i = 1, $table = $(this);
            $table.find('thead th').each(function() {
                var $heading = $(this).text();
                $table.find('tbody td:nth-child('+$i+')').each(function() {
                    $(this).attr("data-label", $heading);
                });
                $i++;
            });
        });
    },
    selectElements:function(){
        $('.selectpicker').on('loaded.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            var $container = $(this).parent('.bootstrap-select');
            $container.width($container.width() + 50);
        });
    },
    scrollToAlert:function(){
        if($('.alert:not(.no-scroll)').length != 0){
            var $anchor = $('.alert').first().offset();
            $('html, body').animate({ scrollTop: $anchor.top - 140 }, 1000)
        }
    },
    scrollToSection:function(){
        $('.header-menu-desktop a[href*="#"], .homepage-slider a[href*="#"], .subpage-header-scrollbutton, a.scroll-to[href*="#"]').click(function(event){
            var $hash = '#' + $(this).attr('href').split('#')[1];
            if ($($hash).length) {
                event.preventDefault();
                var $anchor = $($hash).offset();
                $('.hamburger').removeClass('is-active');
                $('aside').removeClass('active');
                $('html, body').animate({ scrollTop: $anchor.top }, 1000)
            }
        });
    },
    tooltip:function(){
        $('[data-toggle="tooltip"]').tooltip();
    }

};


for(var funkcja in LEMON){
	LEMON[funkcja]();
}